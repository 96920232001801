import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import { useState } from 'react';
import CreativeCoc from './components/CreativeCoC';
import CreativePenalties from './components/CreativePenalties';
import Invoices from './components/Invoices';
import { useSearchParams } from 'react-router-dom';

export default function CreativeFinances() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tab, setTab] = useState<number>(parseInt(searchParams.get('tab')) || 0);

  const handleTabChange = (tab: number) => {
    setSearchParams({tab: tab.toString()});
    setTab(tab);
  };
  

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Text fontSize='25px' fontWeight='bold' m='20px'>
        Photographers finances
      </Text>
      <Tabs index={tab} onChange={handleTabChange}>
        <TabList ml='15px'>
          <Tab>COC</Tab>
          <Tab>Invoices</Tab>
          <Tab>Penalties</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CreativeCoc />
          </TabPanel>
          <TabPanel>
            <Invoices />
          </TabPanel>
          <TabPanel>
            <CreativePenalties />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
