import {useEffect, useState,} from 'react';
import {CountryItem, getData} from 'services/country-list';


const useCountryList = () => {
  // In our hook we use state to hold the value
  const [countryList, setCountryList] = useState<CountryItem[]>([]);

  useEffect(
    () => {
      let isCurrent = true;

      getData().then(
        (countryList) => {
          if (isCurrent) {
            setCountryList(countryList);
          }
        },
      );

      return () => {
        isCurrent = false; // Upon cleanup, we make sure to signal that the current 'useEffect' should be cancelled
      };
    },
    [],
  );

  const getCountryName = (countryCode: string) => {
    const country = countryList.find(c => c.value === countryCode);
    return country ? country.label : countryCode;
  };

  return {countryList, getCountryName};
};

export default useCountryList;
