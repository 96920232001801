export const columnsDataCreative = [
  {
    Header: '',
    accessor: 'adminFlag',
    show: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    sorting: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Activity',
    accessor: 'isInactive',
  },
  {
    Header: 'Country',
    accessor: 'billingAddressObj.country',
    sorting: true,
  },
  {
    Header: 'Hometown',
    accessor: 'homeTown',
    sorting: true,
  },
  {
    Header: 'Average rating',
    accessor: 'averageRating',
    sorting: true,
  },
  {
    Header: '',
    accessor: 'action',
  },
];

export const columnsDataInactiveCreative = [
  {
    Header: '',
    accessor: 'adminFlag',
    show: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    sorting: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Country',
    accessor: 'billingAddressObj.country',
    sorting: true,
  },
  {
    Header: 'Hometown',
    accessor: 'homeTown',
    sorting: true,
  },
  {
    Header: 'Cause of deactivation',
    accessor: 'deactivateReason',
  },
  {
    Header: '',
    accessor: 'action',
  },
];

export const columnsDataNewApplicants = [
  {
    Header: '',
    accessor: 'adminFlag',
    show: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    sorting: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Country',
    accessor: 'billingAddressObj.country',
    sorting: true,
  },
  {
    Header: 'Hometown',
    accessor: 'homeTown',
    sorting: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Labels',
    accessor: 'tags',
  },
  {
    Header: 'Days since application',
    accessor: 'daysSinceApplication',
    sorting: true,
  },
  {
    Header: 'Application date',
    accessor: 'createdAt',
    sorting: true,
  },
  {
    Header: '',
    accessor: 'hiring',
  },
  {
    Header: '',
    accessor: 'manage',
  },
];
export const columnsDataCreativeRating = [
  {
    Header: 'Project',
    accessor: 'projectName',
  },
  {
    Header: 'Client',
    accessor: 'clientName',
  },
  {
    Header: 'Time of rating',
    accessor: 'dateOfRating',
  },
  {
    Header: 'Rating',
    accessor: 'photographerReview.rating',
  },
  {
    Header: 'Rating message',
    accessor: 'photographerReview.message',
  },
  {
    Header: '',
    accessor: 'viewRating',
  },
];

export const columnsDataCreativeProjects = [
  {
    Header: 'Project name',
    accessor: 'projectName',
  },
  {
    Header: 'Client',
    accessor: 'clientName',
  },
  {
    Header: 'Created at',
    accessor: 'createdAt',
  },
  {
    Header: 'Time of shooting',
    accessor: 'shootingTime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'City',
    accessor: 'city',
  },
  {
    Header: '',
    accessor: 'action',
  },
];

export const columnsDataCreativeNewApplicants = [
  {
    Header: 'Név',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Szolgáltatások',
    accessor: 'services',
  },
  {
    Header: 'Ország',
    accessor: 'country',
  },
  {
    Header: 'Város',
    accessor: 'workingArea',
  },
  {
    Header: 'Státusz',
    accessor: 'state',
  },
  {
    Header: 'Műveletek',
    accessor: 'actions',
  },
];

export const columnsDataInaktiveCreatives = [
  {
    Header: 'Név',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Szolgáltatások',
    accessor: 'services',
  },
  {
    Header: 'Ország',
    accessor: 'country',
  },
  {
    Header: 'Város',
    accessor: 'workingArea',
  },
  {
    Header: 'Státusz',
    accessor: 'state',
  },
];

export const columnsDataCheck = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'PROGRESS',
    accessor: 'progress',
  },
  {
    Header: 'QUANTITY',
    accessor: 'quantity',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
];

export const columnsDataColumns = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'PROGRESS',
    accessor: 'progress',
  },
  {
    Header: 'QUANTITY',
    accessor: 'quantity',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
];

export const columnsDataComplex = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
  {
    Header: 'PROGRESS',
    accessor: 'progress',
  },
];
