import {
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

function ConfirmationModal({
    handleSave,
    isOpen,
    onClose,
    data
    }: {
  handleSave: Function;
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
  data: any;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>
          <Text mx='20px' align='center'>Are you sure you want to update this field?</Text>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalFooter>
          <Flex w='100%' justifyContent='center'>
            <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={() => {
                handleSave(data)
                onClose()
              }}
            >
              Save
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmationModal