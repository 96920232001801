import React from "react";

import MenuLayout from "../../../layouts/MenuLayout";
import RoleGuard from "../../../layouts/RoleGuard";

import {adminRoles} from "../../../variables/roles";
import CountrySettings from "./CountrySettings";


export default function CountrySettingsPage() {
  return <RoleGuard
    page
    roles={
      [
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]
    }
  >
    <MenuLayout page={<CountrySettings />} />
  </RoleGuard>
}
