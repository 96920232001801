import React, { useEffect } from "react";
import {
  Box, 
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useCreateCountrySettings } from "../../../api/presence-countries";
import CountrySettingsForm from "./CountrySettingsForm";


export default function NewCountrySettings() {
  const toast = useToast();
  const navigate = useNavigate()

  const [{loading,error},createCountrySettings] = useCreateCountrySettings();

  const handleCreate = async (data: any) => {
    try{
      await createCountrySettings({data})
      toast({
        title: 'Setting successfully created',
        status: 'success',
        isClosable: true,
      });
      navigate('/intel/settings/countries', { replace: true });
    }catch{}
  }

  useEffect(
    () => {
      if (error) {
        toast({
          title: 'Error creating country settings.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [error]
  );

  return <Box
    pt={{ base: "50px", md: "80px", xl: "130px" }}
    display='flex'
    justifyContent="center"
  >
    <CountrySettingsForm loading={loading} submit={createCountrySettings}/>
  </Box>
};
