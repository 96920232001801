
export const downloadFile = (data: any, name: string, type: string) => {
  const blob = new Blob([data], { type });
  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = name;
  link.click();
  URL.revokeObjectURL(downloadUrl);
  document.body.removeChild(link); 
};

export const downloadBase64PDF = (base64Data: string, fileName: string) => {
  const binaryData = atob(base64Data);
  const byteArray = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }

  downloadFile(byteArray, fileName, "application/pdf");
};