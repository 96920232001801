import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Tag,
  Select,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useUpdateClient } from 'api/clients';
import useCountryList from 'hooks/useCountryList';

export default function ClientBillingData(props: { clientData: any }) {
  const { clientData } = props;
  const { clientId } = useParams();
  const {countryList} = useCountryList();
  const [billingEmails, setBillingEmails] = useState(clientData.billingEmails || []);
  const toast = useToast();

  const defaultValues = {
    billingAddress: {
      zipCode: clientData.billingAddress?.zipCode,
      countryCode: clientData.billingAddress?.countryCode || clientData.country,
      city: clientData.billingAddress?.city,
      address: clientData.billingAddress?.address,
    },
    billingEmails: clientData.billingEmails,
    billingSettings: {
      lang: clientData.billingSettings?.lang
    },
    taxId: clientData.taxId
  };

  const {
    handleSubmit,
    register,
    watch,
  } = useForm({
    defaultValues,
  });

  const [{ error: updateClientError, loading }, updateClient] = useUpdateClient(clientId);

  const handleSaveClick = async (data: any) => {
    try{
      console.log('DATA', data)
      const updatedData = { ...data, billingEmails };
      await updateClient({
        data: updatedData,
      });
      toast({
          title: 'Client updated',
          status: 'success',
          duration: 3000,
      });
    }catch{}
  };

  useEffect(
    () => {
      if (updateClientError) {
        toast(
          {
            title: 'Update is unsuccessful!',
            status: 'error',
            duration: 3000,
          }
        )
      }
    },
    [updateClientError, toast]
  );

  const handleRemoveTag = (i: number) => {
    const updatedBillingEmails = [...billingEmails];
    updatedBillingEmails.splice(i, 1);
    setBillingEmails(updatedBillingEmails);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault(); 
      const updatedBillingEmails = [...billingEmails];
      updatedBillingEmails.push(e.target?.value);
      setBillingEmails(updatedBillingEmails);
      e.target.value = '';
    }
  };

  const watchCountry = watch('billingAddress.countryCode')

  return (
    <Box mt='50px'>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex justifyContent='space-around' direction={{ base: 'column', md: 'row' }}>
          <Flex direction='column' alignItems={'center'} justifyContent={'center'}>
            <FormControl id='billingAddress.countryCode' mb={4}>
              <FormLabel>Country</FormLabel>
              <Select 
                {...register('billingAddress.countryCode')} 
                alignItems='center'  
                bg='white' 
                borderRadius='15px'
                value={watchCountry}
              >
                {countryList.map(c=> <option value={c.value} key={c.value}>{c.label} </option>)}
              </Select>
            </FormControl>

            <FormControl id='billingAddress.zipCode' mb={4}>
              <FormLabel>ZIP code</FormLabel>
              <Input variant='main' {...register('billingAddress.zipCode')} />
            </FormControl>

            <FormControl id='billingAddress.city' mb={4}>
              <FormLabel>City</FormLabel>
              <Input variant='main' {...register('billingAddress.city')} />
            </FormControl>

            <FormControl id='billingAddress.address' mb={4}>
              <FormLabel>Address</FormLabel>
              <Input variant='main' {...register('billingAddress.address')} />
            </FormControl>
          </Flex>
          <Flex direction='column' alignItems={'center'} justifyContent={'center'}>
            <FormControl id='taxId' mb={4}>
              <FormLabel>Tax number</FormLabel>
              <Input variant='main' {...register('taxId')} />
            </FormControl>
            <FormControl id='billingSettings.lang' mb={4}>
              <FormLabel>Billing language</FormLabel>
              <Select alignItems='center' defaultValue='en' bg='white' borderRadius='15px'>
                <option value='hu'>Hungarian</option>
                <option value='en'>English</option>
              </Select>
            </FormControl>
            <FormLabel w='100%' ml='10px'>
              Billing emails
            </FormLabel>
            <Flex flexWrap='wrap' w={{ base: '90vw', md: '500px' }} mb='10px'>
              {billingEmails.map((email: string, i: number) => (
                <Tag fontSize='15px' key={i} bg='white' my='10px' mx='5px'>
                  <TagLabel>{email}</TagLabel>
                  <TagCloseButton onClick={() => handleRemoveTag(i)} />
                </Tag>
              ))}
            </Flex>
            <Flex>
              <Input variant='main' onKeyDown={(e) => handleKeyDown(e)}   />
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent='center' m='50px'>
          <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
}