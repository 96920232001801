import React from "react";
import MenuLayout from "../../../layouts/MenuLayout";
import RoleGuard from "../../../layouts/RoleGuard";
import { adminRoles } from "../../../variables/roles";
import NewCountrySettings from "./NewCountrySettings";


export default function NewCountrySettingsPage() {
  return <RoleGuard
    page
    roles={
      [
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]
    }
  >
    <MenuLayout page={<NewCountrySettings />} />
  </RoleGuard>
}
