
export const deactivateReasons = [
  {label: 'Blacklisted', value: 'blacklist'},
  {label: 'Off', value: 'off'},
  {label: 'Vacation', value: 'vacation'},
  {label: 'Other', value: 'other'},
];

export const offDeactivateReasons = [
  {label: 'Vacation', value: 'vacation'},
  {label: 'Other', value: 'other'},
];

export const services = [
  'event',
  'portrait',
  'property',
  'custom',
  'wedding',
  'familyAndGroup',
  'food',
  'product',
];

export const ApplicantStatus = {
  NEW_APPLICANT: 'new-applicant',
  WAITING_FOR_BRIEFING: 'waiting-for-briefing',
  FINISHING_REGISTRATION: 'finishing-registration',
  SETTING_PASSWORD: 'setting-password',
  WAITING_FOR_FIRST_LOGIN: 'waiting-for-first-login',
  TO_BE_DECIDED: 'to-be-decided',
};

export const AllCreativeStatus = {
  ...ApplicantStatus,
  ACTIVE: 'active',
  DELETED: 'deleted',
  INACTIVE: 'inactive'
}

export const Judgement = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  TO_BE_DECIDED: 'to-be-decided',
  NO_SHOW: 'no-show'
}

export const ApplicantLabels = {
  [ApplicantStatus.NEW_APPLICANT]: 'On hold',
  [ApplicantStatus.WAITING_FOR_BRIEFING]: 'Waiting for briefing',
  [ApplicantStatus.FINISHING_REGISTRATION]: 'Finishing registration',
  [ApplicantStatus.SETTING_PASSWORD]: 'Setting password',
  [ApplicantStatus.WAITING_FOR_FIRST_LOGIN]: 'Waiting for first login',
  [ApplicantStatus.TO_BE_DECIDED]: 'Maybe',
};

export const CreativeEventType = {
  REGISTRATION: 'Registration',
  ACCEPT: 'Accepted',
  FINISH_REGISTRATION: 'Finished registration',
  FIRST_LOGIN: 'First login',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  SET_PASSWORD: 'Password set',
  REMOVED_FROM_PACKAGE: 'Removed from package',
  ADDED_TO_PACKAGE: 'Added to package',
  BRIEF_CALL_BOOKED: 'Brief call booked',
  BOOK_CALL_REMINDER: 'Book call reminder',
  SET_PASSWORD_REMINDER: 'Set password reminder',
  FINISH_REGISTRATION_REMINDER: 'Finish registration reminder',
  RESPONSE_COC: 'Got response of COC',
  AUTO_ACCEPT_COC: 'Auto accept COC',
  ADMIN_COMMENT: 'admin-comment',
  WELCOME_HERE: 'accept to work with us',
  NO_SHOW: 'No-show'
};

export const newApplicantStates = Object.entries(ApplicantLabels)
  .map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

export const statusColors: { [key: string]: { primary: string; secondary: string } } = {
  [ApplicantStatus.WAITING_FOR_BRIEFING]: {primary: '#fae38c', secondary: 'black'},
  [ApplicantStatus.FINISHING_REGISTRATION]: {primary: '#B8FA69', secondary: 'black'},
  [ApplicantStatus.WAITING_FOR_FIRST_LOGIN]: {primary: '#a6a3ff', secondary: 'white'},
  [ApplicantStatus.SETTING_PASSWORD]: {primary: '#6DC603', secondary: 'white'},
  [ApplicantStatus.NEW_APPLICANT]: {primary: '#eba834', secondary: 'white'},
  [ApplicantStatus.TO_BE_DECIDED]: {primary: '#D3D3D3', secondary: 'black'},
};

export const CreativeTags: Record<string, string> = {
  INACTIVE: 'inactive',
  FIRST_CALL: 'first-call',
  SMS: 'sms',
  BRIEF_BOOK_REMINDER_1: 'brief-book-reminder-1',
  BRIEF_BOOK_REMINDER_2: 'brief-book-reminder-2',
  BRIEF_BOOK_REMINDER_3: 'brief-book-reminder-3',
  BRIEF_CALL_BOOKED: 'call-booked',
  BRIEF_NO_SHOW_1: 'brief-no-show-1',
  BRIEF_NO_SHOW_2: 'brief-no-show-2',
  BRIEF_NO_SHOW_3: 'brief-no-show-3',
  REG_REMINDER_1: 'reg-reminder-1',
  REG_REMINDER_2: 'reg-reminder-2',
  REG_REMINDER_3: 'reg-reminder-3',
  PW_REMINDER_1: 'pw-reminder-1',
  PW_REMINDER_2: 'pw-reminder-2',
  PW_REMINDER_3: 'pw-reminder-3',
  NOTED: 'noted',
  NO_ANSWER: 'no-answer',
};

export const newApplicantManualTags = [
  CreativeTags.FIRST_CALL,
  CreativeTags.SMS,
  CreativeTags.NOTED,
]

export const tagColors: { [key: string]: { primary: string; secondary: string } } = {
  [CreativeTags.FIRST_CALL]: {primary: '#ffea00', secondary: 'black'},
  [CreativeTags.SMS]: {primary: '#ff8400', secondary: 'white'},
  [CreativeTags.BRIEF_BOOK_REMINDER_1]: {primary: '#fc664c', secondary: 'white'},
  [CreativeTags.BRIEF_BOOK_REMINDER_2]: {primary: '#fc4423', secondary: 'white'},
  [CreativeTags.BRIEF_BOOK_REMINDER_3]: {primary: '#e32402', secondary: 'white'},
  [CreativeTags.BRIEF_CALL_BOOKED]: {primary: '#B8FA69', secondary: 'black'},
  [CreativeTags.BRIEF_NO_SHOW_1]: {primary: '#803b4c', secondary: 'white'},
  [CreativeTags.BRIEF_NO_SHOW_2]: {primary: '#751930', secondary: 'white'},
  [CreativeTags.BRIEF_NO_SHOW_3]: {primary: '#570116', secondary: 'white'},
  [CreativeTags.REG_REMINDER_1]: {primary: '#fc664c', secondary: 'white'},
  [CreativeTags.REG_REMINDER_2]: {primary: '#fc4423', secondary: 'white'},
  [CreativeTags.REG_REMINDER_3]: {primary: '#e32402', secondary: 'white'},
  [CreativeTags.PW_REMINDER_1]: {primary: '#fc664c', secondary: 'white'},
  [CreativeTags.PW_REMINDER_2]: {primary: '#fc4423', secondary: 'white'},
  [CreativeTags.PW_REMINDER_3]: {primary: '#e32402', secondary: 'white'},
  [CreativeTags.NOTED]: {primary: '#118c06', secondary: 'white'},
  [CreativeTags.NO_ANSWER]: {primary: '#A0522D', secondary: 'white'},
};



