import useAxios from './basic/use-axios';

export const useGetCountries = () => useAxios({ url: '/admin/settings/presence-countries' });

export const useCreateCountrySettings = () =>
  useAxios(
    {
      url: '/admin/settings/country',
      method: 'POST',
    },
    { manual: true },
  );

export const useUpdateCountrySettings = (countryCode: string) =>
  useAxios(
    {
      url: `/admin/settings/presence-countries/${countryCode}`,
      method: 'PUT',
    },
    { manual: true },
  );

export const useDeleteCountrySettings = (countryCode: string) =>
  useAxios(
    {
      url: `/admin/settings/presence-countries/${countryCode}`,
      method: 'DELETE',
    },
    { manual: true },
  );

export const useCountrySettings = (countryCode: string) =>
  useAxios({ url: `/admin/settings/presence-countries/${countryCode}` });
