import React, { useEffect } from 'react';

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDeleteCountrySettings } from '../../../api/presence-countries';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useCountryList from 'hooks/useCountryList';

interface CountryCardProps {
  values?: any;
  loading: boolean;
  submit: Function;
}

export default function CountrySettingsForm(props: CountryCardProps) {
  const { countryList } = useCountryList();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const { values, loading, submit } = props;

  const [{ loading: deleteLoading, error }, deleteCountrySetting] = useDeleteCountrySettings(
    values?.countryCode,
  );

  const handleDelete = async (data: any) => {
    try {
      await deleteCountrySetting();
      toast({
        title: 'Setting successfully deleted',
        status: 'success',
        isClosable: true,
      });
      navigate('/intel/settings/countries', { replace: true });
    } catch {}
  };

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error creating country settings.',
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  const countrySettingSchema = z.object({
    countryCode: z.string().nonempty('Country is required'),
    twilioTemplateIds: z.object({ before24hLocalContact: z.string() }),
    exampleInvoiceLink: z.union([
      z.string().url('Invalid URL'),
      z.string().length(0),
    ]),
    invoiceGuideLink: z.union([
      z.string().url('Invalid URL'),
      z.string().length(0),
    ]),
    briefLink: z.union([
      z.string().url('Invalid URL'),
      z.string().length(0),
    ]),
    salesCallLink: z.union([
      z.string().url('Invalid URL'),
      z.string().length(0),
    ]),
    editingNotNeeded: z.boolean(),
    pricingText: z.string(),
  });
  

  const defaultValues = {
    countryCode: values?.countryCode || '',
    twilioTemplateIds: values?.twilioTemplateIds,
    exampleInvoiceLink: values?.exampleInvoiceLink || '',
    invoiceGuideLink: values?.invoiceGuideLink || '',
    briefLink: values?.briefLink || '',
    salesCallLink: values?.salesCallLink || '',
    pricingLink: values?.pricingLink || '',
    editingNotNeeded: values?.editingNotNeeded || false,
    pricingText: values?.pricingText || '',
  };

  const { 
    register, 
    handleSubmit, 
    watch ,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(countrySettingSchema),
  });

  const watchCountry = watch('countryCode');
  const isUpdate = values?.countryCode;

  const handleSave = async (data: any) => {
    try{
      await submit({data})
      toast({
        title: `Country settings successfully ${isUpdate ? 'udated': 'created'}`,
        status: 'success',
        isClosable: true,
      });
      navigate('/intel/settings/countries', { replace: true });
    }catch{}
  }

  return (
    <Flex direction='column'>
      <Text fontSize='20px' fontWeight='bold' mb='20px' align='center'>
        {isUpdate ? 'Edit country settings' : 'Add new country settings'}
      </Text>
      <form onSubmit={handleSubmit(handleSave)}>
  <Flex direction={{ base: 'column', lg: 'row' }}>
    <Flex direction='column' mx='20px' w={{ base: '90%', md: '500px' }}>
      <FormControl id='countryCode' mb={4} w='auto' isInvalid={!!errors.countryCode}>
        <FormLabel>Country</FormLabel>
        <Select
          {...register('countryCode')}
          alignItems='center'
          bg='white'
          borderRadius='15px'
          value={watchCountry}
          disabled={isUpdate}
        >
          <option value={''} key={''}>
            {'Select country'}
          </option>
          {countryList.map((c) => (
            <option value={c.value} key={c.value}>
              {c.label}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl id='exampleInvoiceLink' mb={4} isInvalid={!!errors.exampleInvoiceLink}>
        <FormLabel>Example invoice link</FormLabel>
        <Input variant='main' {...register('exampleInvoiceLink')} />
        <FormErrorMessage>{errors.exampleInvoiceLink?.message.toString()}</FormErrorMessage>
      </FormControl>

      <FormControl id='invoiceGuideLink' mb={4} isInvalid={!!errors.invoiceGuideLink}>
        <FormLabel>Invoice guide link</FormLabel>
        <Input variant='main' {...register('invoiceGuideLink')} />
        <FormErrorMessage>{errors.invoiceGuideLink?.message.toString()}</FormErrorMessage>
      </FormControl>


      <FormControl id='briefLink' mb={4} isInvalid={!!errors.briefLink}>
        <FormLabel>Brief link</FormLabel>
        <Input variant='main' {...register('briefLink')} />
        <FormErrorMessage>{errors.briefLink?.message.toString()}</FormErrorMessage>
      </FormControl>
    </Flex>

    <Flex direction='column' mx='20px' w={{ base: '90%', md: '500px' }}>
      <FormControl id='salesCallLink' mb={4} isInvalid={!!errors.salesCallLink}>
        <FormLabel>Sales call link</FormLabel>
        <Input variant='main' {...register('salesCallLink')} />
        <FormErrorMessage>{errors.salesCallLink?.message.toString()}</FormErrorMessage>
      </FormControl>

      <FormControl id='pricingText' mb={4} isInvalid={!!errors.pricingText}>
        <FormLabel>Pricing text</FormLabel>
        <Textarea variant='main' {...register('pricingText')} />
        <FormErrorMessage>{errors.pricingText?.message.toString()}</FormErrorMessage>
      </FormControl>

      <FormLabel>Twilio template ID-s:</FormLabel>
      <FormControl id='twilioTemplateIds.before24hLocalContact' mb={4} isInvalid={!!errors.twilioTemplateIds}>
        <FormLabel>Before shooting 24h local contact</FormLabel>
        <Input variant='main' {...register('twilioTemplateIds.before24hLocalContact')} />
        <FormErrorMessage>{errors.twilioTemplateIds?.message.toString()}</FormErrorMessage>
      </FormControl>

      <FormControl id='editingNotNeeded' mb={4} display='flex' alignItems='center'>
        <FormLabel mt='8px'>Editing not needed</FormLabel>
        <Checkbox
          {...register('editingNotNeeded')}
					me='10px'
        />
      </FormControl>
    </Flex>
  </Flex>

  <Flex alignItems='center' mt='20px' direction='column' w='100%'>
    <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
      Save
    </Button>
    {isUpdate && (
      <Text mt='10px' textDecor='underline' cursor='pointer' onClick={onOpen}>
        Delete country settings
      </Text>
    )}
  </Flex>
</form>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>
            Are you sure you want to delete this country?
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter display='flex' justifyContent='center'>
            <Button variant='photonMedium' mr={3} onClick={onClose}>
              Back
            </Button>
            <Button variant='photonDark' isLoading={deleteLoading} w='auto' onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
