import {
  Box,
  Button,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  useGetCreativeFeeDocs,
  useGetAllCreativeFeeDocs,
  useGetAllCreativeCertCSV,
  useGetAllCreativeFeeDocsCsv,
} from 'api/staff-api';
import moment from 'moment';

import { useCallback, useEffect, useState } from 'react';
import BillingPeriodSelector from 'views/dashboard/components/BillingPeriodSelector';
import YearSelector from 'views/dashboard/components/YearSelector';
import { DownloadIcon } from '@chakra-ui/icons';
import { currencyFormatter } from 'utils/number-formatter';
import { downloadFile } from 'utils/download-file';
import Card from 'components/card/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import useCountryList from 'hooks/useCountryList';

export default function CreativePenalties() {
  const [year, setYear] = useState(moment().format('YYYY'));
  const [period, setPeriod] = useState('');
  const {getCountryName} = useCountryList();


  const [{ data: creativeFeeDocs }, getCreativeFeeDocs] = useGetCreativeFeeDocs();

  const [{ loading: exportLoading }, getAllCreativeFeeDocsCsv] = useGetAllCreativeFeeDocsCsv();
  const [{ loading: allDocsLoading }, getAllCreativeFeeDocs] = useGetAllCreativeFeeDocs();

  const callGetCreativeFeeDocs = useCallback(async (year: string, period: string) => {
    if (year && period) {
      try {
        await getCreativeFeeDocs({
          params: {
            year,
            period,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callGetAllCreativeFeeDocs = useCallback(async (year: string, period: string) => {
    let result;
    if (year && period) {
      try {
        result = await getAllCreativeFeeDocs({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(
            result.data,
            `certificate_of_completion${year}_${period}.zip`,
            'application/zip',
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callGetAllCreativeFeeDocsCsv = useCallback(async (year: string, period: string) => {
    let result: any;
    if (year && period) {
      try {
        result = await getAllCreativeFeeDocsCsv({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(
            result.data,
            `creative_fee_docs_${year}_${period.replace(' ', '_')}.csv`,
            'application/octet-stream',
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(
    () => {
      callGetCreativeFeeDocs(year, period);
    },
    [year, period],
  );

  const isButtonDisabled = !year || !period || !creativeFeeDocs || creativeFeeDocs?.length === 0;

  return (
    <Card>
      <Text fontSize='18px' fontWeight='bold' m='10px'>
        Contractual penalties
      </Text>
      <Flex justifyContent='space-between'>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <BillingPeriodSelector
            year={year}
            billingPeriod={period}
            setBillingPeriod={setPeriod}
            onePerMonth={true}
          />
        </Flex>
        <Flex>
          <Button
            variant='photonMedium'
            onClick={() => callGetAllCreativeFeeDocsCsv(year, period)}
            m='5px 10px'
            isDisabled={isButtonDisabled}
            w='130px'
            isLoading={exportLoading}
          >
            Export
          </Button>
          <Button
            variant='photonLight'
            onClick={() => callGetAllCreativeFeeDocs(year, period)}
            mt='5px'
            isDisabled={isButtonDisabled}
            isLoading={allDocsLoading}
          >
            Download all
          </Button>
        </Flex>
      </Flex>
      <Flex justifyContent='center'>
        {creativeFeeDocs?.length > 0 ? (
          <TableContainer w='100%' mt='50px'>
            <Table variant='simple'>
              <Thead>
                <Tr borderBottom={`solid #E2E8F0`}>
                  <Th>Creative</Th>
                  <Th textAlign='center'>Country</Th>
                  <Th textAlign='center'>Billing period</Th>
                  <Th textAlign='center'>Document</Th>
                </Tr>
              </Thead>
              <Tbody>
                {creativeFeeDocs.map((row: any, i: string) => (
                  <Tr key={i}>
                    <Td>{row.creative}</Td>
                    <Td textAlign='center'> {getCountryName(row.country)}</Td>
                    <Td textAlign='center'>{row.date}</Td>
                    <Td textAlign='center'>
                      <Link isExternal href={row.doc?.url}>{<DownloadIcon />}</Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Text textAlign={'center'} mt='50px'>
            There is no document available for this period.
          </Text>
        )}
      </Flex>
    </Card>
  );
}
