export const decodeStringArrayParam = (queryParams: any, name: string) => {
  let value = Object.fromEntries(queryParams.entries())[name] || ([] as any);
  if (typeof value === 'string') {
    value = decodeURIComponent(value).split(',');
  }
  if (name === 'workingArea'){
   value =  value.map((v: string) => decodeURIComponent(v).replace('-', ','))
  }
  return value;
};

export const decodeStringParam = (queryParams: any, name: string) => {
  let value = Object.fromEntries(queryParams.entries())[name] || ([] as any);
  value = decodeURIComponent(value);
  return value;
};

export const decodeBooleanParam = (queryParams: any, name: string) => {
  return Object.fromEntries(queryParams.entries())[name] === 'true'
};
