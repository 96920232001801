import { Box, Tag } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


interface ColumnProps {
  id: string;
}

const SortableColumn: React.FC<ColumnProps> = ({ id }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  return (
    <Box ref={setNodeRef} transform={CSS.Transform.toString(transform)} transition={transition} {...attributes} {...listeners}>
      <Tag key={id} id={id} fontSize="16px">
        {id}
      </Tag>
    </Box>
  );
}

export default SortableColumn;
