import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Flex,
  Link,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import {AiFillStar} from 'react-icons/ai';
import {useParams} from 'react-router-dom';
import moment from 'moment-timezone';
import Bugsnag from '@bugsnag/js';

import {useGetProject, useUpdateProject} from '../../api/projects';


import CancelProjectButton from './components/CancelProjectButton';
import ProjectBasicData from './components/ProjectBasicData';
import ProjectContributors from './components/ProjectContributors';
import ProjectImagesAndFinances from './components/ProjectImagesAndFinances';
import ProjectEventsAndDocuments from './components/ProjectEventsAndDocuments';
import {useFormContext} from 'contexts/useFormContext';
import config from '../../config';
import {statusColors} from './variables/styles';

const tabLabels = ['Basic data', 'Contributors', 'Images & Finances', 'Events & Documents'];

export default function Project() {
  const {projectId} = useParams();
  const {handleTabChange} = useFormContext();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [{error: updateProjectError}, updateProject] = useUpdateProject(projectId);
  const toast = useToast();

  const [
    {
      data: projectData,
      loading: getProjectLoading,
      error: getProjectError,
    },
    getProject,
  ] = useGetProject(projectId);

  useEffect(
    () => {
      if (updateProjectError) {
        toast({
          title: 'Could not update project',
          description: updateProjectError.message,
          status: 'error',
          duration: 3000,
        });
        const errorText = `${updateProjectError.message}\n\nstack: ${updateProjectError.stack}`;
        Bugsnag.notify(new Error(errorText));
        console.error(errorText);
      }
    },
    [updateProjectError, toast],
  );

  useEffect(
    () => {
      if (getProjectError) {
        toast({
          title: 'Could not getting project',
          description: getProjectError.message,
          status: 'error',
          duration: 3000,
        });
        const errorText = `${getProjectError.message}\n\nstack: ${getProjectError.stack}`;
        Bugsnag.notify(new Error(errorText));
        console.error(errorText);
      }
    },
    [getProjectError, toast],
  );

  const handleAdminFlagChange = async (value: boolean) => {
    await updateProject({data: {adminFlag: value}});
    toast({
      title: 'Project updated',
      status: 'success',
      duration: 3000,
    });
    getProject();
  };

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      {
        getProjectLoading
        && <Box
          position="absolute"
          zIndex={10}
          top={0}
          bottom={0}
          left={0}
          right={0}
          backgroundColor="whiteAlpha.700" // 40% transparency
          display="flex"
          alignItems="center"
          justifyContent="center"
          transform="scale(1)"
        >
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='brand.600'
            size='xl'
          />
        </Box>
      }
      {
        projectData
        && <Box ml='20px'>
          <Flex
            alignItems='center'
            direction={{base: 'column', md: 'row'}}
            justifyContent={{base: 'center', md: 'space-between'}}
          >
            <Flex
              bg='white'
              alignItems='center'
              w={{base: '90vw', md: 'auto'}}
              minW={{base: '90vw', md: '500px'}}
              borderRadius='20px'
              m='10px'
              p='10px'
              direction={{base: 'column', md: 'row'}}
            >
              <Flex
                justifyContent='space-between'
                w={{base: '75vw', md: 'auto'}}
                minW={{base: '90vw', md: '380px'}}
                alignItems={{base: 'center', md: 'start'}}
                direction='column'
              >
                <Text fontSize='25px' fontWeight='bold'>
                  {projectData.projectName}
                </Text>
                <Text align='right' fontWeight='bold'>
                  {moment(projectData.shootingTime)
                    ?.tz(projectData.ianaCode ?? 'Europe/Budapest')
                    .format('DD.MM.YYYY HH:mm')}{' '}
                  (Local time zone)
                </Text>
                <Text align='right' fontWeight='bold'>
                  {projectData.locationObj?.city}
                </Text>
              </Flex>
              <Flex
                direction={'column'}
                h='100px'
                justifyContent={{base: 'center', md: 'start'}}
                alignItems='center'
              >
                <Box
                  bg={statusColors[projectData.status]?.primary}
                  borderRadius='20px'
                  padding='10px'
                  mb='10px'
                  ml='10px'
                >
                  <Text
                    align='center'
                    fontWeight='bold'
                    color={statusColors[projectData.status]?.secondary}
                  >
                    {projectData.status}
                  </Text>
                </Box>
                <AiFillStar
                  onClick={() => {
                    handleAdminFlagChange(!projectData.adminFlag);
                  }}
                  cursor='pointer'
                  color={projectData.adminFlag ? '#6b52ff' : 'lightgrey'}
                  size='25'
                />
              </Flex>
            </Flex>
            <Flex alignItems='center' direction={'column'}>
              <Link target='_blank' isExternal href={projectData.storage?.url}>
                <Button variant='photonLight'>
                  Go to drive folder
                </Button>
              </Link>


              <CancelProjectButton
                project={projectData}
                refetchTableData={getProject}
                withButton={true}
              />
                <Link
                  target='_blank'
                  isExternal
                  href={`${config.oldAdminUrl}/#/enterprise-projects/${projectData._id}/show`}
                >
                  <Button variant='photonMedium' w='200px' mt='10px'>
                    Old Admin
                  </Button>
                </Link>
            </Flex>
          </Flex>
          <Tabs index={selectedTabIndex}>
            <TabList>
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  w={{base: '25%', md: 'auto'}}
                  onClick={() => handleTabChange(index, setSelectedTabIndex)}
                >
                  {label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <ProjectBasicData projectData={projectData} refetch={getProject}/>
              </TabPanel>

              <TabPanel>
                <ProjectContributors projectData={projectData} refetch={getProject}/>
              </TabPanel>

              <TabPanel>
                <ProjectImagesAndFinances projectData={projectData} refetch={getProject}/>
              </TabPanel>

              <TabPanel>
                <ProjectEventsAndDocuments projectData={projectData}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      }
    </Box>
  );
}
