import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Box,
} from '@chakra-ui/react';
import { useManageCreative } from 'api/staff-api';
import { ApplicantStatus, Judgement } from '../constants';
import { useNavigate } from 'react-router-dom';

const ACCEPT_A = `Are you sure you want to move the photographer to Waiting for briefing status?`
const ACCEPT_B = `Are you sure you want to accept the photographer's application?`

const REJECT = [`Are you sure you want to reject the photographer's application?`, `Shall we allow the photographer to re-apply later?`, `Provide the reason for rejection!`]
const RESTART = `Are you sure you want to restart the application process?`

export default function HiringModal({
  creativeId,
  judgement,
  isOpen,
  onClose,
  refetch,
  status
}: {
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
  judgement: string;
  creativeId: string;
  refetch: Function;
  status?: string
}) {
  const toast = useToast();
  const navigate = useNavigate();

  const [isConfirmValid, setConfirmValid] = useState(undefined);
  const [confirmName, setConfirmName] = useState('');
  const [rejectStatus, setRejectStatus] = useState(0)

  const [{ error: manageCreativeError, loading: manageCreativeLoading }, manageCreative] =
    useManageCreative();

  useEffect(() => {
    setRejectStatus(0)
  }, [isOpen])

  const handleManageCreative = async () => {
    try {
      const res = await manageCreative({
        data: {
          judgement,
        },
        params: {
          creativeId,
        },
      });
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      refetch();
      onClose();
    } catch {
      toast({
        title: 'Error during updating hiring status',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const deactivate = async () => {
    try {
      const res = await manageCreative({
        data: {
          judgement: 'off',
        },
        params: {
          creativeId,
        },
      });
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      refetch();
      onClose();
    } catch {
      toast({
        title: 'Error during updating hiring status',
        status: 'error',
        duration: 3000,
      });
    }
  }

  const rejectReason = async (reason: string) => {
    try {
      const res = await manageCreative({
        data: {
          judgement,
          reason
        },
        params: {
          creativeId,
        },
      });
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      refetch();
      onClose();
      navigate('/intel/creatives/new-applicant', { replace: true });
    } catch {
      toast({
        title: 'Error during updating hiring status',
        status: 'error',
        duration: 3000,
      });
    }
  }

  const isRejecting = judgement !== 'accepted'

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setConfirmValid(undefined);
        setConfirmName('');
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Text>
          {judgement === Judgement.ACCEPTED && status === ApplicantStatus.NEW_APPLICANT && ACCEPT_A }
          {judgement === Judgement.ACCEPTED && status !== ApplicantStatus.NEW_APPLICANT && ACCEPT_B }
          {judgement === Judgement.NO_SHOW && RESTART}

          {judgement === Judgement.REJECTED &&
            (<>
              {REJECT[rejectStatus]}

              {rejectStatus === 2 && (
                <Box my="5px">
                  <Button m='5px' w='140px' variant='photonMedium' onClick={() => rejectReason("equipment")}>
                    No equipment
                  </Button>
                  <Button m='5px' w='140px' variant='photonMedium' onClick={() => rejectReason("company")}>
                    No company
                  </Button>
                  <Button m='5px' w='140px' variant='photonMedium' onClick={() => rejectReason("interest")}>
                    Not interested
                  </Button>
                  <Button m='5px' w='140px' variant='photonMedium' onClick={() => rejectReason("pricing")}>
                    Pricing
                  </Button>
                  <Button m='5px' w='140px' variant='photonMedium' onClick={() => rejectReason("taxing")}>
                    EU VAT
                  </Button>
                  <Button m='5px' w='140px' variant='photonMedium' onClick={() => rejectReason("other")}>
                    Other
                  </Button>
                </Box>)}
            </>)}
        </Text>
        </ModalBody>
        <ModalFooter>
          {!isRejecting || isRejecting && rejectStatus < 2
            ? (<>
                {rejectStatus === 0
                  ? (<Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
                      Cancel
                    </Button>)
                  : (<Button mr='5px' w='140px' variant='photonMedium' onClick={deactivate}>
                      No
                    </Button>)}
                <Button
                  w='140px'
                  variant='photonDark'
                  onClick={!isRejecting ? handleManageCreative : () => {
                    if(rejectStatus === 1) {
                      handleManageCreative()
                    }

                    setRejectStatus(prevRejectStatus => prevRejectStatus + 1)
                  }}
                  isLoading={manageCreativeLoading}
                >
                  Yes, proceed
                </Button>
              </>)
            : (<>
                <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>Cancel</Button>
                <Button
                  w='140px'
                  variant='photonDark'
                  onClick={handleManageCreative}
                  isLoading={manageCreativeLoading}
                >
                  Yes, proceed
                </Button>
              </>)
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
