import React, {useEffect} from "react";

import {
  Box,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import {useNavigate, useParams} from 'react-router-dom';
import {useCountrySettings, useUpdateCountrySettings} from "../../../api/presence-countries";
import CountrySettingForm from "./CountrySettingsForm";


export default function CountrySettings() {
  const {countryCode} = useParams();
  const toast = useToast();
  const navigate = useNavigate()

  const [
    {
      data,
      loading,
      error,
    },
    reloadSettings,
  ] = useCountrySettings(countryCode);

  const [
    {
      loading: updateLoading, 
      error: updateError
    },
    updateCountrySettings
  ] = useUpdateCountrySettings(countryCode);


  useEffect(
    () => {
      if (error) {
        toast({
          title: 'Error getting presence country settings.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [error]
  );

  useEffect(
    () => {
      if (updateError) {
        toast({
          title: 'Error updating country settings',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [updateError]
  );

  return <Box
    pt={{ base: "50px", md: "80px", xl: "130px" }}
    minH="90vh"
    display='flex'
    justifyContent="center"
    alignItems="center"
    flexWrap="wrap"
  >
    {
      error
      && <Text fontSize="xl" fontWeight="extrabold">
        Error during getting data: {error.response.data.message}
      </Text>
    }

    {
      loading
      && <Box minH="30vh">
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='brand.600'
          size='xl'
        />
      </Box>
    }
    {
      !loading
      && !error
      && data
      && <CountrySettingForm values={data} loading={updateLoading} submit={updateCountrySettings}/>
    }
  </Box>
};
